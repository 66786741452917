// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tn7HY{bottom:0;display:contents;height:100vh;height:100svh;pointer-events:none;position:fixed;top:0;width:100vw}", "",{"version":3,"sources":["webpack://./src/pages/layout.module.css"],"names":[],"mappings":"AAAA,OAGI,QAAS,CAFT,gBAAiB,CAMjB,YAAc,CACd,aAAe,CAHf,mBAAoB,CAHpB,cAAe,CAEf,KAAM,CAEN,WAGJ","sourcesContent":[".overlay {\n    display: contents;\n    position: fixed;\n    bottom: 0;\n    top: 0;\n    pointer-events: none;\n    width: 100vw;\n    height:  100vh;\n    height:  100svh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "tn7HY"
};
export default ___CSS_LOADER_EXPORT___;
